<template>
    <div>
        <div class="container d-flex align-items-center vh-100 justify-content-center">
            <div>
                <div class="text-center">
                    <div class="logo-wrapper">
                        <img src="@/assets/img/berlin_logo.png"/>
                    </div>
                </div>
                <div class="text-center my-5">
                    <span>
                        <i class="ri-emotion-sad-line ri-8x m-0 p-0"></i>
                    </span>
                    <h1 class="mb-1">{{ $t('page_not_found') }}</h1>
                    <h4 class="mb-5">{{ $t('sorry_we_could_not_find_the_page_you_were_looking_for') }}</h4>
                    <div class="d-flex justify-content-center">
                        <b-button size="sm" variant="link" @click="goBack" class="small mt-5">
                            <i class="ri-arrow-left-line mr-2 h6 mb-0 font-weight-normal top-plus-0"></i>
                            {{ $t('turn_back') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Error404',
        methods: {
            goBack() {
                this.$router.push('/')
            }
        }
    }
</script>
